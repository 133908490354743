import { Injectable } from '@angular/core';
import { Role } from '../models/role';
import { AppPlatform } from '../models/app-platform';
import { LANGUAGE_CODES, LANGUAGE_CODES_FULL } from '../constants/constants';
import { TransactionType } from '../models/transaction-type';

@Injectable({
  providedIn: 'root',
})
export class TypeAccessorService {
  constructor() {}

  public get Role(): typeof Role {
    return Role;
  }

  public get AppPlatform(): typeof AppPlatform {
    return AppPlatform;
  }

  public get LANGUAGE_CODES(): string[] {
    return LANGUAGE_CODES;
  }

  public get LANGUAGE_CODES_FULL(): string[] {
    return LANGUAGE_CODES_FULL;
  }

  public get TransactionType(): typeof TransactionType {
    return TransactionType;
  }
}
