import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalConfig } from '../models/global-config';

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigService {
  private globalConfigSubject: BehaviorSubject<GlobalConfig>;
  public globalConfig: Observable<GlobalConfig>;

  constructor(private http: HttpClient) {
    this.globalConfigSubject = new BehaviorSubject<GlobalConfig>(null);
    this.globalConfig = this.globalConfigSubject.asObservable();
  }

  public get value(): GlobalConfig {
    return this.globalConfigSubject.value;
  }

  private get baseUrl() {
    return `${environment.apiUrl}/global-config`;
  }

  async refresh(): Promise<void> {
    const globalConfig = await this.http.get<GlobalConfig>(`${this.baseUrl}/all`).toPromise();
    console.log('globalConfig', globalConfig);
    this.globalConfigSubject.next(globalConfig);
  }

  public async update(globalConfig: GlobalConfig) {
    const result = await firstValueFrom(this.http.put<GlobalConfig>(`${this.baseUrl}/all`, globalConfig));
    this.globalConfigSubject.next(result);
    return result;
  }
}
