import { Injectable } from '@angular/core';
import { translationMap } from '../constants/translations';
import { Translation } from '../models/translation';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private language: string;

  constructor() {
    this.loadLanguageFromBrowser();
  }

  setLanguage(language: string) {
    if (!language) {
      return;
    }
    this.language = language;
    console.log('set language to', language);
  }

  get currentLanguage(): string {
    return this.language;
  }

  get map(): Translation {
    return translationMap[this.language];
  }

  private loadLanguageFromBrowser() {
    const browserLanguage = navigator.language;
    this.language = 'en';
    if (browserLanguage) {
      // convert en-US to en, etc; account for no dash
      const split = browserLanguage.split('-');
      if (split.length > 0) {
        this.setLanguage(split[0]);
      }
    }
  }
}
