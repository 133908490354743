import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './constants/app.initializer';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AccountService } from './services/account.service';
import { ErrorService } from './services/error.service';
import { GlobalConfigService } from './services/global-config.service';
import { UserActivityService } from './services/user-activity.service';
import { TranslationService } from './services/translation.service';
import { NgxStripeModule } from 'ngx-stripe';
import { GlobalErrorHandlerService } from './constants/global-error-handler';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.isProduction,
      registrationStrategy: 'registerWithDelay:5000',
    }),
    NgxStripeModule.forRoot(environment.stripePublishableKey),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [
        AccountService,
        Router,
        GlobalConfigService,
        UserActivityService,
        ErrorService,
        TranslationService,
      ],
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
