import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../services/error.service';
import { ApiLog } from '../models/api-log';
import { AccountService } from '../services/account.service';
import { ApiLogLevel } from '../models/api-log-level';
import { removeCircularReferences } from './general-utils';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private errorService: ErrorService, private accountService: AccountService) {}

  handleError(error: any) {
    this.sendLogToApi(error);
  }

  sendLogToApi(error: any) {
    // skip http error logs, they are already logged in the interceptor
    if (error instanceof HttpErrorResponse) {
      return;
    }

    const apiLog: ApiLog = {
      created: new Date().toISOString(),
      accountId: this.accountService.accountValue?.id || null,
      userAgent: navigator.userAgent,
      level: ApiLogLevel.Error,
      status: error.status || null,
      uiUrl: window.location.href,
      apiUrl: null,
      request: null,
      response: this.errorService.getErrorMessage(error),
    };
    this.errorService.sendLogToApi(apiLog);

    console.error(error);
  }
}
