import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccountService } from '../services/account.service';
import { Account } from '../models/account';
import { TranslationService } from '../services/translation.service';
import { ErrorService } from '../services/error.service';
import { ApiLog } from '../models/api-log';
import { ApiLogLevel } from '../models/api-log-level';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private activeRefreshRequest: Promise<Account> | null = null;

  constructor(
    private accountService: AccountService,
    private translationService: TranslationService,
    private errorService: ErrorService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if account is logged in and request is to the api url
    const account = this.accountService.accountValue;
    const isLoggedIn = account && account.jwtToken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${account.jwtToken}`,
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          'Accept-Language': this.translationService.currentLanguage,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          'Accept-Language': this.translationService.currentLanguage,
        },
      });
    }

    // try the request, catch 401 errors and refresh token, then retry the request (without breaking the observable chain)
    return next.handle(request).pipe(
      catchError(async (reqError: HttpErrorResponse) => {
        if (reqError.status === 401 && !request.url.includes('/auth/refresh-token')) {
          console.log('401 error, refreshing token');
          try {
            if (!this.activeRefreshRequest) {
              this.activeRefreshRequest = this.accountService.refreshToken();
            }
            await this.activeRefreshRequest;
            this.activeRefreshRequest = null;
          } catch (refreshError) {
            await this.accountService.logout();
            throw refreshError;
          }
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.accountService.accountValue?.jwtToken}`,
            },
          });
          return next.handle(request).toPromise();
        } else {
          if (
            !['/authenticate', '/register', '/refresh-token', '/reset-password', '/logs'].some(url =>
              request.url.includes(url)
            )
          ) {
            const apiLog: ApiLog = {
              created: new Date().toISOString(),
              accountId: this.accountService.accountValue?.id || null,
              userAgent: navigator.userAgent,
              level: ApiLogLevel.Error,
              status: reqError.status,
              uiUrl: window.location.href,
              apiUrl: request.url,
              request: JSON.stringify(request),
              response: JSON.stringify(reqError),
            };
            this.errorService.sendLogToApi(apiLog);
          }
          throw reqError;
        }
      })
    );
  }
}
