import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { Role } from './models/role';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then(m => m.VerifyEmailPageModule),
  },
  {
    path: 'verify-phone',
    loadChildren: () => import('./pages/verify-phone/verify-phone.module').then(m => m.VerifyPhonePageModule),
  },
  {
    path: 'account-email-preference',
    loadChildren: () =>
      import('./pages/account-email-preference/account-email-preference.module').then(
        m => m.AccountEmailPreferencePageModule
      ),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'recordings',
    loadChildren: () => import('./pages/recordings/recordings.module').then(m => m.RecordingsPageModule),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'waitlist-entries',
    loadChildren: () =>
      import('./pages/waitlist-entries/waitlist-entries.module').then(m => m.WaitlistEntriesPageModule),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'call-history',
    loadChildren: () => import('./pages/call-history/call-history.module').then(m => m.CallHistoryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./pages/subscription/subscription.module').then(m => m.SubscriptionPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notification-settings',
    loadChildren: () =>
      import('./pages/notification-settings/notification-settings.module').then(
        m => m.NotificationSettingsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'prompts',
    loadChildren: () => import('./pages/prompts/prompts.module').then(m => m.PromptsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'default-prompts',
    loadChildren: () => import('./pages/prompts/prompts.module').then(m => m.PromptsPageModule),
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import('./pages/transactions/transactions.module').then(m => m.TransactionsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'waitlist',
    loadChildren: () => import('./pages/waitlist/waitlist.module').then(m => m.WaitlistPageModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule),
  },
  {
    path: 'mobile-app',
    loadChildren: () => import('./pages/mobile-app/mobile-app.module').then(m => m.MobileAppPageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
