import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../services/account.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = this.accountService.accountValue;
    if (account) {
      // check if route is restricted by role
      if (route.data['roles'] && !route.data['roles'].includes(account.role)) {
        console.log('not authorized for route');
        // role not authorized so redirect to home page
        if (state.url !== '/') {
          this.router.navigate(['/']);
        }
        return false;
      }

      if (!account.isOnboarded) {
        if (state.url.startsWith('/welcome')) {
          return true;
        }
        this.router.navigate(['/welcome']);
        return false;
      }

      if (!account.isVerifiedEmail) {
        if (state.url.startsWith('/verify-email')) {
          return true;
        }
        this.router.navigate(['/verify-email']);
        return false;
      }

      if (!account.isVerifiedPhone || !account.phone) {
        if (state.url.startsWith('/verify-phone')) {
          return true;
        }
        this.router.navigate(['/verify-phone']);
        return false;
      }

      // if there is a redirect query param, redirect to it
      const queryParams = route.queryParams;
      if (queryParams['redirect']) {
        this.router.navigate([queryParams['redirect']]);
      }

      // authorized so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    if (state.url.startsWith('/login')) {
      return true;
    } else {
      const newQueryParams = state.url.length > 1 ? { redirect: state.url } : {};
      this.router.navigate(['/login'], {
        queryParams: newQueryParams,
      });
    }

    return false;
  }
}
