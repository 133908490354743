import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  public userActivity$ = new Subject<any>();

  constructor() {}

  public async init(): Promise<void> {
    window.addEventListener('mousemove', this.onUserActivity.bind(this));
    window.addEventListener('keypress', this.onUserActivity.bind(this));
    window.addEventListener('mousedown', this.onUserActivity.bind(this));
    window.addEventListener('touchstart', this.onUserActivity.bind(this));
  }

  public onUserActivity(event: any): void {
    this.userActivity$.next(event);
  }
}
