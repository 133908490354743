import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { interval } from 'rxjs';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root',
})
export class NgswHelperService {
  constructor(
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private tls: TranslationService
  ) {}

  public async init(): Promise<void> {
    // console.log('swUpdate.isEnabled', this.swUpdate.isEnabled);
    // const isWebsite = isPlatform('desktop') || isPlatform('mobileweb');
    // console.log('isWebsite', isWebsite);
    if (this.swUpdate.isEnabled) {
      let isShowingToast = false;
      const checkInterval$ = interval(60 * 1000);
      checkInterval$.subscribe(async () => {
        const isNewUpdate = await this.swUpdate.checkForUpdate();
        console.log('SW isNewUpdate?', isNewUpdate);
        if (isNewUpdate) {
          if (!isShowingToast) {
            isShowingToast = true;
            await this.showUpdateToast();
            isShowingToast = false;
          }
        }
      });

      // this method does not work after 1 update
      // this.swUpdate.versionUpdates.subscribe(async (event) => {
      //   console.log('SW update event', event);
      //   if (event.type === 'VERSION_READY' && !isShowingToast) {
      //     isShowingToast = true;
      //     await this.showUpdateToast();
      //     isShowingToast = false;
      //   }
      // });

      this.swUpdate.unrecoverable.subscribe(async (event) => {
        console.log('SW unrecoverable error', event);
        if (!isShowingToast) {
          isShowingToast = true;
          await this.showUpdateToast();
          isShowingToast = false;
        }
      });
    }
  }

  private async showUpdateToast(): Promise<void> {
    let toast = await this.toastCtrl.create({
      message: this.tls.map.newVersionAvailable,
      position: 'bottom',
      color: 'secondary',
      buttons: [
        {
          text: this.tls.map.reload,
          role: 'cancel',
          handler: async () => {
            await this.swUpdate.activateUpdate();
            setTimeout(() => {
              document.location.reload();
            }, 100);
          },
        },
      ],
    });
    await toast.present();
    await toast.onDidDismiss();
  }
}
