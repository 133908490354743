import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { TOAST_DURATION } from '../constants/constants';
import { ApiLog } from '../models/api-log';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private toastCtrl: ToastController, private httpClient: HttpClient) {}

  private get baseUrl() {
    return `${environment.apiUrl}/logs`;
  }

  async displayError(prefix: string, error: any, reportToApi: boolean): Promise<void> {
    const fullErrorMessage = `${prefix}: ${this.getErrorMessage(error)}`;

    const toast = await this.toastCtrl.create({
      message: fullErrorMessage,
      duration: TOAST_DURATION,
      color: 'danger',
    });
    await toast.present();
  }

  public sendLogToApi(apiLog: ApiLog): Promise<void> {
    return this.httpClient.post<void>(this.baseUrl, apiLog).toPromise();
  }

  public getErrorMessage(error: any): string {
    if (typeof error?.error === 'object') {
      // If the error is an object, it's an error from the API
      return this.getErrorMessage(error.error);
    }
    if (error.errors) {
      const keys = Object.keys(error.errors);
      if (keys.length) {
        // Get the first error message of the first error field
        return error.errors[keys[0]][0];
      }
    }
    return error.error?.message || error.message || error;
  }
}
