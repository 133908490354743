export const environment = {
  name: 'prod',
  isProduction: true,
  apiUrl: 'https://db.juntotalk.com',
  webAppUrl: 'https://app.juntotalk.com',
  stripePublishableKey:
    'pk_live_51Ms6PALVlBsx0ZUqz0vGOJCvYLq5UAq48fPTL6DOkitVRdzLBTcFpU3F4F3mcP95ZqF6H3KWLwhi062P62MuVCHp00P7sQ2Niz',
  googleAnalytics: 'TODO',
  stripeClientId: 'TODO',
};
