export const TOAST_DURATION = 3000;
export const MAX_IMAGE_SIZE_MBYTES = 5;
export const MAX_IMAGE_SIZE_BYTES = MAX_IMAGE_SIZE_MBYTES * 1024 * 1024;
export const GOOGLE_PLAY_APP_URL = 'TODO';
export const APP_STORE_APP_URL = 'TODO';
export const GOOGLE_PLAY_LONG_TAIL_APP_URL = 'TODO';
export const APP_STORE_LONG_TAIL_APP_URL = 'TODO';
export const LANGUAGE_CODES = ['en', 'es', 'pt'];
export const LANGUAGE_CODES_FULL = ['en', 'es', 'pt', 'fr', 'de', 'ja', 'it', 'ko', 'zh', 'hi', 'ru', 'ar'];
export const PROMPT_LIST_VERSION = 1;
export const PROMPT_METADATA_VERSION = 1;
